<template>
  <div class="row">
    <ed-alert class="col-12  text-left mt-3 mb-3" v-if="formData.strMensagemMigracao" type="error">{{ formData.strMensagemMigracao }}</ed-alert>
    <ed-table
      class="col-12"
      :key="$root.$session.versao"
      strModel="System/Migracao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :config="{
        registerName: 'Atualizar Base',
        registerIcon: $utilidade.getIcone('logs'),
      }"
      @search="getMigracao"
      @register="atualizarMigrations"
      disabledEditRows
      disabledDeleteRows
    />
  </div>
</template>

<script>
import { EdTable,EdAlert } from "@/components/common/form";
export default {
  name: "EdModelo",
  inject:['formatarCliente','getCliente'],
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdTable,
    EdAlert
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        objTipoModelo: null,
        arrayParametros: [],
      },
      loading: false,
      arrayHeaders: [
        // {
        //   text: "Código",
        //   sortable: true,
        //   value: "intId",
        //   width:"100px"
        // },
        {
          text: "Arquivo",
          sortable: true,
          value: "strVersao",
        },
        {
          text: "Executado",
          sortable: true,
          value: "boolExecutado",
        },
        {
          text: "Data",
          sortable: true,
          value: "strDataCadastro",
        },
        {
          text: "Mensagem",
          sortable: true,
          value: "strMensagem",
          collapse: 20,
          collapseLabel: "Ver mais",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {
        intClienteId: this.formData.intId,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.getMigracao();
    },

    atualizarMigrations() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("System/Migracao", {
          intClienteId: this.formData.intId,
          boolAtualizarMigracao: true,
        })
        .then((objResult) => {
          this.$root.$dialog.loading(false);

          if (objResult.status == 200 && objResult.result) {
            this.getCliente()
            //this.formatarCliente(objResult.result);
          }

          this.getMigracao();
        });
    },

    getMigracao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("System/Migracao", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModelo) => {
              let strCor = "green";

              if (!objModelo.boolExecutado) {
                strCor = "red";
              }
              let item = {
                intId:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModelo.intId +
                  "</span>",
                  strVersao:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModelo.strVersao +
                  "</span>",
                boolExecutado:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  (objModelo.boolExecutado ? "Sim" : "Não") +
                  "</span>",
                strMensagem:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModelo.strMensagem +
                  "</span>",
                strDataCadastro:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  this.$utilidade.toDate(objModelo.strDataCadastro) +
                  "</span>",
                _item: objModelo,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
