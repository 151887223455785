<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
      <span class="form-title">Módulos Disponívels</span>

      <div class="row">
        <div class="col-12">
          <ed-simple-table disabledSearchRows disabledRegisterRows>
            <thead>
              <tr>
                <th>Módulo</th>
                <th>Descrição</th>
                <th>Valor Padrão</th>
                <th>Valor Cobrado</th>
                <th>Habilitado</th>
              </tr>
            </thead>
            <tbody v-if="formData.modulos">
              <tr v-for="(objModulo, index) in bind.arrayModulos" :key="index">
                <td>{{ objModulo.strNome }}</td>
                <td>{{ objModulo.strDescricao }}</td>
                <td>
                  R$ {{ $utilidade.floatToMoney(objModulo.floatPrecoVenda) }}
                </td>
                <td>
                  <ed-input-money
                    :name="'floatValor-' + index"
                    v-model="formData.modulos[objModulo.intId].floatPreco"
                  />
                </td>
                <td>
                  <ed-input-switch
                    :name="'boolAtivo-' + index"
                    :disabled="objModulo.intId == 1"
                    v-model="formData.modulos[objModulo.intId].boolAtivo"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>TOTAL</th>
                <th></th>
                <th>R$ {{ $utilidade.floatToMoney(getTotalModulo()) }}</th>
                <th>R$ {{ $utilidade.floatToMoney(getTotalAplicado()) }}</th>
                <th></th>
              </tr>
            </tfoot>
          </ed-simple-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputSwitch,
  EdInputMoney,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputSwitch,
    EdInputMoney,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      arrayCnpjConsultado: [],
      objDadosDocumento: null,
    };
  },
  watch: {},
  methods: {
    setCnae(item) {
      if (item?.strCodigo) {
        this.formData.intGrauRisco = item.intGrauRisco;
      }
    },

    getTotalModulo() {
      let total = 0;

      this.bind.arrayModulos.forEach((element) => {
        total += Number(element.floatPrecoVenda);
      });
      return total;
    },

    getTotalAplicado() {
      let total = 0;
      let self = this 

      Object.keys(this.formData.modulos).forEach(function (key) {

        total += Number(self.$utilidade.moneyToFloat(self.formData.modulos[key].floatPreco));
      });

      return total;
    },

    getDadosDocumento() {
      if (
        !this.formData.intId &&
        this.formData.strCnpj &&
        this.$utilidade.isCnpj(this.formData.strCnpj) &&
        this.arrayCnpjConsultado.indexOf(this.formData.strCnpj) == -1
      ) {
        this.$root.$dialog.loading(true);

        this.$root.$request
          .get("Cadastro/CadastroGeral", {
            boolConsultarDocumento: true,
            strCnpj: this.formData.strCnpj,
          })
          .then((objResponse) => {
            this.$root.$dialog.loading(false);

            if (objResponse.status == 200 && objResponse.result) {
              this.objDadosDocumento = objResponse.result;

              this.arrayCnpjConsultado.push(this.formData.strCnpj);

              this.formData.strNome = objResponse.result.strNome;
              this.formData.strCnae = objResponse.result.strCnae;
              this.formData.intGrauRisco = objResponse.result.intGrauRisco;
              this.formData.strTelefone = objResponse.result.strTelefone;
              this.formData.endereco = Object.assign(
                objResponse.result.endereco,
                {}
              );

              if (!this.formData.setores || !this.formData.setores.length) {
                this.formData.setores = Object.assign(
                  [
                    {
                      intId: 0,
                      intGhe: 1,
                      strNome: "ADMINISTRATIVO",
                      boolAtivo: true,
                    },
                  ],
                  {}
                );
              }

              if (!!this.formData.unidades || this.formData.unidades.length) {
                this.formData.unidades = Object.assign(
                  [
                    {
                      intId: 0,
                      intUnidade: 1,
                      strNome: "MATRIZ",
                      strCnpj: this.$utilidade.onlyNumber(
                        this.formData.strCnpj
                      ),
                      intTipoDocumentoId: 1,
                      intTipoEstabelecimentoId: 1,
                      boolAtivo: true,
                    },
                  ],
                  {}
                );
              }

              if (objResponse.result.emails.length) {
                if (!this.formData.emails || !this.formData.emails.length) {
                  this.formData.emails = Object.assign(
                    objResponse.result.emails,
                    {}
                  );
                }
              }

              this.$forceUpdate();
            }
          });
      }
    },
  },
};
</script>
