<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-banco">
        Banco de Dados
        <i :class="$utilidade.getIcone('banco') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-permissao">
        Permissões
        <i :class="$utilidade.getIcone('permissao') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-email" v-if="formData.intId">
        Email
        <i :class="$utilidade.getIcone('email') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-cobranca" v-if="false">
        Cobrança
        <i :class="$utilidade.getIcone('cobranca') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent && bind.boolCarregado"
    >
      <cadastro-geral :formData="formData" v-show="strTab == 'tab-cadastro'" />
      <banco :formData="formData" v-show="strTab == 'tab-banco'" />
      <permissao :formData="formData" v-show="strTab == 'tab-permissao'" />
      <ed-log-email boolDisableEnvioEmail :strNomeDatabase="formData.strNomeDatabase" :strIdentificador="'cliente.'+formData.intId" :formData="formData" v-if="strTab == 'tab-email' && formData.intId" />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral";
import Banco from "./partials/banco";
import Permissao from "./partials/permissao";
import EdLogEmail from "@/components/common/log/email/index";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intId: {
      type: [Number, String],
      required: true,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    Banco,
    Permissao,
    EdLogEmail
  },
  provide() {
    return {
      bind: this.bind,
      formatarCliente: this.formatarCliente,
      getCliente:this.initialize
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      bind: {
        arrayModulos: [],
        boolCarregado:false
      },
      formData: {
        intId: 0,
        intClienteId: null,
        strTipoPessoa: "PJ",
        strNome: null,
        strCnpj: null,
        strNomeDatabase: null,
        strUrlDatabase: null,
        strUrlSistema: null,
        strDataBloqueio: null,
        boolAtivo: 1,
        boolSystemAdmin: 0,
        boolHabilitado: 1,
        modulos: [],
        endereco: {
          intId: 0,
          strGrupoEsocial: 2,
          strCidade: null,
          intCep: null,
          strEstado: null,
          strLogradouro: null,
          strNumero: null,
          strComplemento: null,
          strBairro: null,
        },
      },
    };
  },
  watch: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("System/Cliente", {
          boolDadosIniciais: true,
          intId: this.intId,
        }),
      ]).then(([objDados]) => {
        this.$root.$dialog.loading(false);

        if (objDados.status == 200) {
          if (objDados.result.modulos) {
            this.bind.arrayModulos = objDados.result.modulos;
          }

          if (objDados.result.cliente) {
            this.formatarCliente(objDados.result.cliente);
          } 

          this.formData.modulos = this.formatarModulos();
          this.bind.boolCarregado = true;
        }
      });
    },

    formatarModulos() {
      let modulos = {};

      this.bind.arrayModulos.forEach((objModulo) => {
        let boolAtivo = false;
        let floatPreco = objModulo.floatPrecoVenda;
        this.formData.modulos.forEach((element) => {
          if (element.modulo.intId == objModulo.intId) {
            boolAtivo = element.boolAtivo;
            floatPreco = element.floatPreco;
          }
        });

    
        modulos[objModulo.intId] = {
          intModuloId: objModulo.intId,
          boolAtivo: boolAtivo,
          floatPreco: floatPreco,
        };
      });


      return _.cloneDeep(modulos);
    },

    formatarCliente(objCadastroGeral) {
      if (!objCadastroGeral.endereco) {
        objCadastroGeral.endereco = {
          intId: 0,
          strCidade: null,
          intCep: null,
          strEstado: null,
          strLogradouro: null,
          strNumero: null,
          strComplemento: null,
          strBairro: null,
        };
      }

      this.formData = Object.assign(objCadastroGeral,{});
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.formData.intTipoCadastroGeralId = this.intTipoCadastroGeralId;

      this.$root.$request
        .post("System/Cliente", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let strRota = this.$route.name;

            if (strRota.indexOf(".register") != -1) {
              this.$router.push({
                name: strRota.replace(".register", ".edit"),
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.formatarCliente(objResponse.result);
            }
          }
        });
    },
  },
};
</script>
