<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 text-left">
      <span class="form-title">Informações Gerais</span>

      <div class="row mb-3">
        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.intClienteId"
          label="Compartilhar Base de Dados"
          route="System/Cliente"
          initializeItems
          clearable
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strNomeDatabase"
          name="strNomeDatabase"
          label="Nome do Banco"
          :rules="formData.intClienteId ? '' : 'required'"
          :disabled="formData.intClienteId ? true : false"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strUrlDatabase"
          name="strUrlDatabase"
          label="Endereço de Acesso"
          :rules="formData.intClienteId ? 'required' : 'required'"
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strDataBloqueio"
          name="strDataBloqueio"
          label="Bloquear acesso em"
          clearable
        />

        <!-- <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strUrlSistema"
          name="strUrlSistema"
          label="Url API"
          :rules="formData.intClienteId ? 'required' : 'required'"
        /> -->

        <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
          v-model="formData.boolAtivo"
          name="boolAtivo"
          label="Ativo?"
        />

        <!-- <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
          v-model="formData.boolSystemAdmin"
          name="boolSystemAdmin"
          label="System Admin"
          info="Liberar essa permissão vai permitir esse cliente ter acesso master no sistema."
        /> -->

        <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
          v-model="formData.boolHabilitado"
          name="boolHabilitado"
          label="Habilitado"
          info="Parâmetro que define se o cliente está apto para utilizar o sistema"
        />
      </div>

      <template v-if="formData.intId">
        <span class="form-title">Atualização da Base</span>
        <div class="row">
          <migracao :formData="formData"></migracao>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Migracao from "./migracao.vue";

import {
  EdAlert,
  EdSimpleTable,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputTime,
  EdInputDate,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdAlert,
    EdInputText,
    EdSimpleTable,
    EdInputSelect,
    EdInputTipo,
    EdInputTextArea,
    EdInputTime,
    EdInputDate,
    EdInputSwitch,
    EdInputAutoComplete,
    Migracao,
  },
  inject: ["bind","formatarCliente"],
  provide() {
    return {
      formatarCliente:this.formatarCliente
    };
  },
  
  mounted() {},
  created() {},
  data() {
    return {
      arrayCnpjConsultado: [],
      objDadosDocumento: null,
    };
  },
  watch: {},
  methods: {
    setCnae(item) {
      if (item?.strCodigo) {
        this.formData.intGrauRisco = item.intGrauRisco;
      }
    },

    formatarUrlSistema(){
      this.formData.strUrlSistema = process.env.VUE_APP_URL_API+'/'+this.formData.strUrlDatabase
    },

    getDadosDocumento() {
      if (
        !this.formData.intId &&
        this.formData.strCnpj &&
        this.$utilidade.isCnpj(this.formData.strCnpj) &&
        this.arrayCnpjConsultado.indexOf(this.formData.strCnpj) == -1
      ) {
        this.$root.$dialog.loading(true);

        this.$root.$request
          .get("Cadastro/CadastroGeral", {
            boolConsultarDocumento: true,
            strCnpj: this.formData.strCnpj,
          })
          .then((objResponse) => {
            this.$root.$dialog.loading(false);

            if (objResponse.status == 200 && objResponse.result) {
              this.objDadosDocumento = objResponse.result;

              this.arrayCnpjConsultado.push(this.formData.strCnpj);

              this.formData.strNome = objResponse.result.strNome;
              this.formData.strCnae = objResponse.result.strCnae;
              this.formData.intGrauRisco = objResponse.result.intGrauRisco;
              this.formData.strTelefone = objResponse.result.strTelefone;
              this.formData.endereco = Object.assign(
                objResponse.result.endereco,
                {}
              );

              if (!this.formData.setores || !this.formData.setores.length) {
                this.formData.setores = Object.assign(
                  [
                    {
                      intId: 0,
                      intGhe: 1,
                      strNome: "ADMINISTRATIVO",
                      boolAtivo: true,
                    },
                  ],
                  {}
                );
              }

              if (!!this.formData.unidades || this.formData.unidades.length) {
                this.formData.unidades = Object.assign(
                  [
                    {
                      intId: 0,
                      intUnidade: 1,
                      strNome: "MATRIZ",
                      strCnpj: this.$utilidade.onlyNumber(
                        this.formData.strCnpj
                      ),
                      intTipoDocumentoId: 1,
                      intTipoEstabelecimentoId: 1,
                      boolAtivo: true,
                    },
                  ],
                  {}
                );
              }

              if (objResponse.result.emails.length) {
                if (!this.formData.emails || !this.formData.emails.length) {
                  this.formData.emails = Object.assign(
                    objResponse.result.emails,
                    {}
                  );
                }
              }

              this.$forceUpdate();
            }
          });
      }
    },
  },
};
</script>
